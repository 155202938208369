/** @jsx jsx */

import { jsx, Spinner, Text, Message } from "theme-ui";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useFirestore } from "react-redux-firebase";

import { get, noop } from "sites-common/utils/lodash";
import { containerStyles } from "sites-common/utils/fabricStyles";
import useMyBoolean from "sites-common/hooks/useMyBoolean";
import {
  ActionButton,
  DefaultButton,
  Stack,
  StackItem,
} from "office-ui-fabric-react";
import DynamicForm from "@heartfulnessinstitute/react-hfn-forms/dist/DynamicForm";
import PropTypes from "prop-types";
import copyKeys from "sites-common/utils/copyKeys";
import ConfirmButton from "gatsby-plugin-hfn-profile/components/ConfirmButton";
import { getZone } from "gatsby-plugin-hfn-profile/components/SrcmEntities/Render/useZoneForCenter";

import { profileApiUrl } from "sites-common/service-wrappers/profile-api";
import DLWLayout from "../../layouts/dlw-fixed";

///
/// config
///

const collection = `AbhyasiMentoring`;
const storeAs = collection;
const listenerSettings1 = {
  collection,
  storeAs,
};
const formFields = [
  "date_added",
  "mentor_name",
  "mentor_center",
  "mentor_zone",
].map((r) => ({ name: r, label: r }));
const defaultValues = {};

const needfix = (r) => !r.mentor_zone;

const howManyNeedFix = (allrecords) => {
  return allrecords.filter(needfix).length;
};

const whatToFix = (record) => {
  // console.log(record.mentor_center, record.mentor_zone);

  if (needfix(record)) {
    // console.log("1",record.mentor_center, record.mentor_zone);
    return fetch(profileApiUrl(`/api/v2/groups/?name=${record.mentor_center}`))
      .then((R) => R.json())
      .then((R) => {
        try {
          const centerId = R.results[0].id;
          // console.log("center", centerId);
          if (centerId) {
            // return new Promise((resolve, reject) => {
            //   resolve({mentor_zone: centerId});
            // });
            return getZone(centerId).then((z) => ({ mentor_zone: z.name }));
          }
          return new Promise((resolve) => {
            resolve(null);
          });
        } catch (e) {
          return new Promise((resolve) => {
            resolve(null);
          });
        }
      });
  }
  return new Promise((resolve) => {
    resolve(null);
  });
};

///
/// Regular DB Rendering with CRUD
///

const RenderRecord = ({ a }) => {
  const firestore = useFirestore();

  const [editMode, { setTrue: setEditMode, setFalse: clearEditMode }] =
    useMyBoolean(false);

  const onSubmit = useCallback(
    (data, sCb, fCb) => {
      const fields = copyKeys(
        data,
        formFields.map((f) => f.name)
      );
      firestore
        .set(`${collection}/${a.id}`, fields, { merge: true })
        .then(() => {
          sCb();
        })
        .catch((e) => {
          fCb(e.toString());
        });
    },
    [a.id, firestore]
  );

  const onDelete = useCallback(() => {
    firestore
      .delete(`${collection}/${a.id}`)
      .then(() => {})
      .catch((e) => {
        console.error(e.toString());
      });
  }, [a.id, firestore]);

  if (editMode) {
    return (
      <Message>
        <DynamicForm
          formFields={formFields}
          defaultValues={a}
          onSubmit={onSubmit}
          onDiscard={clearEditMode}
        />
      </Message>
    );
  }

  return (
    <Message>
      <Stack horizontal horizontalAlign="space-between">
        <StackItem>
          <DynamicForm
            formFields={[{ name: "id", label: "id" }, ...formFields]}
            defaultValues={a}
            printOnly
          />
        </StackItem>
        <Stack>
          <ActionButton
            text="edit"
            iconProps={{ iconName: "Edit" }}
            onClick={setEditMode}
          />
          <ConfirmButton title={`Deleting ${a.id}`} onClick={onDelete}>
            <ActionButton text="delete" iconProps={{ iconName: "Trash" }} />
          </ConfirmButton>
        </Stack>
      </Stack>
    </Message>
  );
};

RenderRecord.propTypes = {
  a: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
};

const NewRecord = ({ onDiscard }) => {
  const firestore = useFirestore();

  const onSubmit = useCallback(
    (data, sCb, fCb) => {
      const fields = copyKeys(
        data,
        formFields.map((f) => f.name)
      );
      firestore
        .add(`${collection}`, fields)
        .then(() => {
          sCb();
        })
        .catch((e) => {
          fCb(e.toString());
        });
    },
    [firestore]
  );

  return (
    <Message>
      <DynamicForm
        formFields={formFields}
        defaultValues={defaultValues}
        onSubmit={onSubmit}
        onDiscard={onDiscard}
      />
    </Message>
  );
};

NewRecord.propTypes = {
  onDiscard: PropTypes.func,
};
NewRecord.defaultProps = {
  onDiscard: noop,
};
function RenderDB() {
  const firestore = useFirestore();
  const allrecords = useSelector((state) =>
    get(state.firestore.ordered, [storeAs], [])
  );
  const [add, { setTrue: setAdd, setFalse: clearAdd }] = useMyBoolean(false);

  const [showrecs, { toggle }] = useMyBoolean(false);
  const [counter, setCounter] = useState(0);
  const [showfixrecs, { toggle: toggleFixRecs }] = useMyBoolean(false);

  const fixRecords = useCallback(() => {
    setCounter(allrecords.length);
    allrecords.forEach((a) => {
      whatToFix(a).then((fieldsToFix) => {
        // console.log(fieldsToFix);

        if (fieldsToFix) {
          // do fix it
          firestore
            .set(`${collection}/${a.id}`, fieldsToFix, { merge: true })
            .then(() => {
              setCounter((s) => s - 1);
            })
            .catch((e) => {
              console.error(e.toString());
            });
        } else {
          setCounter((s) => s - 1);
        }
      });
    });
  }, [allrecords, setCounter, firestore]);

  useEffect(() => {
    const listenerSettings = { ...listenerSettings1 };
    firestore.setListener(listenerSettings);
    return function cleanup() {
      firestore.unsetListener(listenerSettings);
    };
  }, [firestore]);

  return (
    <div>
      <Text style={containerStyles.flexcenter} variant="header">
        Firebase Collection - {collection}
      </Text>
      <Text style={containerStyles.flexcenter} variant="subtitle">
        Admin Console
      </Text>
      {allrecords === undefined && <Spinner />}
      {allrecords !== undefined && (
        <div>
          {!add && (
            <DefaultButton
              sx={{ mt: 4 }}
              iconProps={{ iconName: "Add" }}
              text="Add New Record"
              onClick={setAdd}
            />
          )}
          {add && <NewRecord onDiscard={clearAdd} />}
          <DefaultButton
            sx={{ mt: 4 }}
            iconProps={{ iconName: "AutoEnhanceOn" }}
            text={`Fix ${howManyNeedFix(allrecords)} Records`}
            onClick={fixRecords}
          />
          <DefaultButton
            sx={{ mt: 4 }}
            iconProps={{ iconName: "AutoEnhanceOn" }}
            text={`Show ${howManyNeedFix(allrecords)} Records`}
            onClick={toggleFixRecs}
          />
          <DefaultButton
            sx={{ mt: 4 }}
            iconProps={{ iconName: "AllApps" }}
            text={`${(showrecs ? "Hide " : "Show ") + allrecords.length} Recs`}
            onClick={toggle}
          />
          <div>
            <Message>{counter}</Message>
          </div>
          {showfixrecs &&
            allrecords.filter(needfix).map((a) => {
              return (
                <div sx={{ my: 3 }}>
                  <RenderRecord a={a} key={a.id} />
                </div>
              );
            })}
          {showrecs &&
            allrecords.map((a) => {
              return (
                <div sx={{ my: 3 }}>
                  <RenderRecord a={a} key={a.id} />
                </div>
              );
            })}
        </div>
      )}
    </div>
  );
}

function FixFirestore() {
  return (
    <DLWLayout sx={{ bg: "white", pb: 4 }}>
      <RenderDB />
    </DLWLayout>
  );
}

export default FixFirestore;
