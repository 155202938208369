import { useEffect, useState } from "react";
import { profileApiCachedUrl } from "sites-common/service-wrappers/profile-api";

const fetchGroup = (id) => {
  return fetch(profileApiCachedUrl("groups-id", id))
    .then((R) => R.json())
    .catch(() => -1);
};

export const getZone = (centerId) => {
  if (!centerId) {
    Promise.resolve(-1);
  }
  return fetchGroup(centerId).then((group) => {
    if (!group || group === -1) {
      Promise.resolve(-1);
    }
    if (["zone", "region", "whq"].includes(group.group_type)) {
      return group;
    }
    return getZone(group.parent);
  });
};

const useZoneForCenter = ({ centerId }) => {
  const [zone, setZone] = useState(null);
  useEffect(() => {
    if (centerId) {
      getZone(centerId).then((z) => setZone(z));
    }
  }, [centerId]);
  return zone === -1 ? { name: "-NA-" } : zone;
};

export default useZoneForCenter;
